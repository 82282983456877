<template>
  <div class="tiktokPage" @keydown="returnFn($event)">
    <div
      class="back tabindex"
      tabindex="1"
      @keydown="keyDown($event, '/')"
      @click="goPath('/')"
    >
      Exit
    </div>
    <ul class="channelList">
      <li class="channelItem" :class="currentChannel==item.channelName?'active':''" v-for="(item,index) in tiktokData" :key="index" @click="setCurrentChannel(item)">{{item.channelName}}</li>
    </ul>
    <ul class="tiktokList">
      <template v-if="currentChannel!='Karaoke'">
        <li class="tiktokListItem" v-for="(item,index) in videoList" :key="index"  @click="playTiktok(item)">
          <img :src="require(`../../assets/tiktok/img/${item.imgUrl}`)" alt="">
        </li>
      </template>
      <template v-else>
        <li class="karaokeListItem" v-for="(item,index) in videoList" :key="index"  @click="playKaraoke(item)">
          <img :src="require(`../../assets/tiktok/img/${item.imgUrl}`)" alt="">
        </li>
      </template>
    </ul>
    <div id="playPage" v-if="showPlay" >
      <div class="closePlayer" @click="closePlayer">X</div>
        <video v-if="tiktokPlay" :src="require(`../../assets/tiktok/video/${playUrl}`)" autoplay id="itemPlay"></video>
        <div class="iframeContent" v-else v-html="karaokeIframe"></div>
    </div>
  </div>
</template>
<script>
import tiktokJson from '../../assets/tiktok.json'
export default {
  data () {
    return {
      tiktokJson,
      showPlay: false,
      currentChannel: 'Group',
      playUrl: '',
      tiktokPlay: true,
      karaokeIframe: ''
    }
  },
  computed: {
    tiktokData () {
      return this.tiktokJson.ticktokData.channel
    },
    videoList () {
      return (this.tiktokData.filter((item) => {
        return item.channelName === this.currentChannel
      }))[0].content
    }
  },
  mounted () {
  },
  methods: {
    goPath (path) {
      this.$router.push(path)
    },
    setCurrentChannel (item) {
      this.currentChannel = item.channelName
    },
    playTiktok (item) {
      this.tiktokPlay = true
      this.showPlay = true
      this.playUrl = item.videoUrl
    },
    playKaraoke (item) {
      this.tiktokPlay = false
      this.showPlay = true
      this.karaokeIframe = item.videoUrl
    },
    closePlayer () {
      this.showPlay = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tiktokPage{
  height: 100%;
  position: relative;
  .back {
    display: block;
    width: 80px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  .channelList{
    width: 100%;
    height: 50px;
    color: #fff;
    display: -webkit-flex;
    -webkit-justify-content: space-evenly;
    -webkit-align-items: center;
    padding-top:20px;
    .channelItem{
      width: 160px;
      height: 50px;
      border-radius: 10px;
      line-height: 50px;
      font-size: 40px;
      text-align: center;
    }
    .active{
      background-color: #fff;
      color: #000;
    }
  }
  .tiktokList{
    width: 100%;
    height: 580px;
    margin-top: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    -webkit-justify-content: space-evenly;
    -webkit-align-items: center;
    -webkit-flex-wrap: wrap;
    .tiktokListItem{
      width: 250px;
      height: 450px;
      position: relative;
      border: 1px solid #fff;
      border-radius:10px ;
      img{
        display: block;
        width: 250px;
        height: 450px;
        border-radius:10px ;
      }
      .itemDis{
        position: absolute;
        width: 240px;
        padding: 3px 5px;
        line-height:30px;
        font-size: 23px;
        color: #fff;
        bottom: 0px;
        word-wrap: break-word;
      }
    }
    .karaokeListItem{
      width: 450px;
      height: 250px;
      position: relative;
      border: 1px solid #fff;
      border-radius:10px ;
      box-sizing: border-box;
      img{
        display: block;
        width: 100%;
        height:100%;
        border-radius:10px ;
      }
    }
  }
  #playPage{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9) ;
    .closePlayer{
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 45px;
      text-align: center;
      font-weight: 600;
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
    }
    video{
      width: 480px;
      height: 750px;
      display: block;
      margin: 0 auto;
    }
    .iframeContent{
      width: 100%;
      height: 700px;
      margin-top:50px ;
    }
  }
}
</style>
